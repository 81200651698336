
import { renderData, formatName } from "@libraryHelpers/dataComponents.js";
import { licenseItems } from "@libraryHelpers/licenses.js";
import {
	getNumbersOnlyFromPhoneFormat,
	formatPhoneNumber,
} from "@libraryHelpers/regex";

export default {
	props: {
		settings: {
			type: Object,
			required: true,
		},
		dataSite: {
			type: Object,
			required: true,
		},
		isEditable: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			entityTitles: {
				"FINANCIAL ADVISOR": "Eagle Strategies LLC",
				"WEALTH ADVISOR": "Eagle Strategies LLC",
				"INVESTMENT ADVISOR REPRESENTATIVE": "Eagle Strategies LLC",
				"FINANCIAL PLANNER": "Eagle Strategies LLC",
				"REGISTERED REPRESENTATIVE": "NYLIFE Securities LLC",
				AGENT: "New York Life Insurance Company",
				"LICENSED AGENT": "New York Life Insurance Company",
			},
			photoLocation: "",
			widestTextComputed: false,
			widestText: 0,
		};
	},
	computed: {
		isAgent() {
			return !this.isDBA || !this.toggleDBA;
		},
		isGo() {
			if (this.isDBA) return false;
			if (this.toggleGo !== undefined) {
				// some older sites in prod do not currently have the goName-show setting
				return this.toggleGo;
			} else {
				return this.dataSite.tags?.includes(1305) ?? false;
			}
		},
		toggleButton() {
			return this.settings?.button;
		},
		toggleButton2() {
			return this.settings?.button2;
		},
		toggleDBA() {
			return this.settings?.toggleDba;
		},
		toggleGo() {
			return this.settings["goName-show"];
		},
		toggleDesignation() {
			return this.settings?.toggleDesignation;
		},
		toggleEntityTitle() {
			return (
				this.settings?.toggleTitle &&
				Object.keys(this.entityTitles).includes(
					this.blockContactTitle?.toUpperCase()
				)
			);
		},
		toggleImage() {
			return this.settings?.toggleImage;
		},
		imageHeight() {
			const height = this.settings?.height;
			if (!height) return "auto";

			return `${height}px`;
		},
		imageWidth() {
			const width = this.settings?.width;
			if (!width) return "100%";

			return `${width}px`;
		},
		toggleLicense() {
			return this.settings?.toggleLicense;
		},
		toggleName() {
			return this.settings?.toggleName;
		},
		toggleTitle() {
			return this.settings?.toggleTitle;
		},
		dbaOnly() {
			return !this.toggleName && !this.toggleTitle;
		},
		noPhoto() {
			return !this.blockPhoto && !this.photoTop && !this.photoBackground;
		},
		blockPhoto() {
			if (this.isMemberSelected)
				return this.memberSelected.headshotImageURL;
			return renderData(this.mediaMetadataPhoto, this.dataSite);
		},
		mediaMetadataPhoto() {
			const mediaSrc = this.settings?.photo;
			return mediaSrc && typeof mediaSrc === "object"
				? mediaSrc.src
				: mediaSrc;
		},
		photoMedia() {
			if (this.isMemberSelected)
				return this.memberSelected.headshotImageURL;
			const mediaSrc = this.settings?.photo;
			const { crop, focal } = mediaSrc;
			if (crop || focal) {
				const mediaObject = { src: crop || {} };
				if (focal) mediaObject.src.focal = focal;
				return mediaObject;
			}
			return { src: this.settings?.mediaMetadata?.photo };
		},
		altText() {
			return this.settings?.photo.alt;
		},
		blockLogo() {
			return this.mediaMetadataLogo || null;
		},
		mediaMetadataLogo() {
			const mediaSrc = this.settings?.logo;
			return mediaSrc && typeof mediaSrc === "object"
				? mediaSrc.src
				: mediaSrc;
		},
		logoMedia() {
			const mediaSrc = this.settings?.logo;
			const { crop, focal } = mediaSrc;
			if (crop || focal) {
				const mediaObject = { src: crop };
				if (focal) mediaObject.src.focal = focal;
				return mediaObject;
			}
			return { src: this.settings?.mediaMetadata?.logo };
		},
		blockGoName() {
			if (!this.toggleGo) return "";
			return renderData(
				`${this.settings.goName} General Office`,
				this.dataSite
			);
		},
		managingPartner() {
			if (this.isMemberSelected) return "";
			if (
				this.dataSite.user?.ddcUserData?.marketerTitleTpDesc?.toLowerCase() ===
				"managing partner"
			) {
				return renderData(
					this.dataSite.user?.ddcUserData?.marketerTitleTpDesc,
					this.dataSite
				);
			}

			return false;
		},
		blockContactName() {
			if (this.isMemberSelected)
				return (
					this.memberSelected?.displayName ||
					`${this.memberSelected?.firstName} ${this.memberSelected?.lastName}`
				);
			const formattedContactName = formatName(
				this.settings.name,
				this.dataSite?.user?.ddcUserData
			);
			return (
				this.displayName ||
				renderData(formattedContactName, this.dataSite)?.trim() ||
				""
			);
		},
		displayName() {
			return this.dataSite?.user?.ddcUserData?.displayName;
		},
		designationCharacters() {
			return this.blockContactDesignation?.length;
		},
		blockContactDesignation() {
			if (this.isMemberSelected) return "";
			return renderData(this.settings.designation || "", this.dataSite);
		},
		htmlFormattedDesignation() {
			const data = this.blockContactDesignation;

			if (!data) return;

			const tag = "sup";
			const symbolClass = "block__name_designation-symbol";
			const designationArray = data
				.split(",")
				.map((d, index, arr) =>
					index < arr.length - 1
						? `<span>${d.trim()},&nbsp;</span>`
						: `<span>${d.trim()}</span>`
				);
			const designation = designationArray.join("");

			// below matches: ©, ® and ™
			const symbolRegex = /&copy;|\u00A9|&reg;|\u00AE|&trade;|\u2122/gi;
			return (
				"&nbsp;" +
				designation?.replace(
					symbolRegex,
					`<${tag} class="${symbolClass}">$&</${tag}>`
				)
			);
		},
		blockContactTitle() {
			if (this.isMemberSelected) return this.memberSelected.title;
			return renderData(this.settings.title, this.dataSite);
		},
		blockEntityTitle() {
			return this.entityTitles[this.blockContactTitle?.toUpperCase()];
		},
		blockDBAName() {
			const name = renderData(
				this.settings.dbaname,
				this.dataSite
			)?.trim();
			return name || null;
		},
		blockContactDescription() {
			if (this.isMemberSelected) return "";
			return this.settings.description;
		},
		blockContactLandline() {
			if (!this.settings?.["landline-show"]) return "";
			if (this.isMemberSelected)
				return formatPhoneNumber(this.memberSelected.phoneNumber);

			const phoneNumber = getNumbersOnlyFromPhoneFormat(
				renderData(this.settings.landline, this.dataSite)
			);

			if (!phoneNumber) return "";

			const extension = renderData(
				this.settings.extension,
				this.dataSite
			);

			const value = `${formatPhoneNumber(phoneNumber)?.replace(
				" ext. ",
				""
			)}${extension ? ` ext. ${extension}` : ""}`;

			return value;
		},
		blockContactLandlineLabel() {
			if (this.isMemberSelected) return "";
			if (this.settings.landlineLabel) {
				return renderData(
					this.settings.landlineLabel + ": &nbsp;",
					this.dataSite
				);
			}
			return false;
		},
		blockContactAltPhone1() {
			if (!this.settings?.["alt_phone_1-show"]) return "";

			const phoneNumber = getNumbersOnlyFromPhoneFormat(
				renderData(this.settings.alt_phone_1, this.dataSite)
			);

			if (!phoneNumber) return "";

			const extension = renderData(
				this.settings.extension_alt_phone_1,
				this.dataSite
			);

			const value = `${formatPhoneNumber(phoneNumber)?.replace(
				" ext. ",
				""
			)}${extension ? ` ext. ${extension}` : ""}`;

			return value;
		},
		blockContactAltPhone1Label() {
			const label = this.settings.alt_phone_1Label;
			if (label && label.length <= 100) {
				return renderData(
					this.settings.alt_phone_1Label + ": &nbsp;",
					this.dataSite
				);
			}
			return false;
		},
		blockContactAltPhone2Label() {
			const label = this.settings.alt_phone_2Label;
			if (label && label.length <= 100) {
				return renderData(
					this.settings.alt_phone_2Label + ": &nbsp;",
					this.dataSite
				);
			}
			return false;
		},
		blockContactAltPhone2() {
			if (!this.settings?.["alt_phone_2-show"]) return "";

			const phoneNumber = getNumbersOnlyFromPhoneFormat(
				renderData(this.settings.alt_phone_2, this.dataSite)
			);

			if (!phoneNumber) return "";

			const extension = renderData(
				this.settings.extension_alt_phone_2,
				this.dataSite
			);

			const value = `${formatPhoneNumber(phoneNumber)?.replace(
				" ext. ",
				""
			)}${extension ? ` ext. ${extension}` : ""}`;

			return value;
		},
		blockContactAltPhone3Label() {
			const label = this.settings.alt_phone_3Label;
			if (label && label.length <= 100) {
				return renderData(
					this.settings.alt_phone_3Label + ": &nbsp;",
					this.dataSite
				);
			}
			return false;
		},
		blockContactAltPhone3() {
			if (!this.settings?.["alt_phone_3-show"]) return "";

			const phoneNumber = getNumbersOnlyFromPhoneFormat(
				renderData(this.settings.alt_phone_3, this.dataSite)
			);

			if (!phoneNumber) return "";

			const extension = renderData(
				this.settings.extension_alt_phone_3,
				this.dataSite
			);

			const value = `${formatPhoneNumber(phoneNumber)?.replace(
				" ext. ",
				""
			)}${extension ? ` ext. ${extension}` : ""}`;

			return value;
		},
		blockContactMobile() {
			if (!this.settings?.["mobile-show"]) return "";
			if (this.isMemberSelected) return "";

			const phoneNumber = getNumbersOnlyFromPhoneFormat(
				renderData(this.settings.mobile, this.dataSite)
			);
			return formatPhoneNumber(phoneNumber);
		},
		blockContactFax() {
			if (!this.settings?.["fax-show"]) return "";
			if (this.isMemberSelected) return "";

			const phoneNumber = getNumbersOnlyFromPhoneFormat(
				renderData(this.settings.fax, this.dataSite)
			);
			return formatPhoneNumber(phoneNumber);
		},
		blockContactEmail() {
			if (!this.settings?.["email-show"]) return "";
			if (this.isMemberSelected) return this.memberSelected.emailAddress;

			return renderData(this.settings.email, this.dataSite);
		},
		hasAddressSection() {
			if (!this.settings?.["address-show"]) return false;

			return !!(
				this.blockContactAddressLine1 ||
				this.blockContactAddressCity ||
				this.blockContactAddressState ||
				this.blockContactAddressZip
			);
		},
		hasAddress2Section() {
			if (!this.settings?.["address2-show"]) return false;

			return !!(
				this.blockContactAddress2Line1 ||
				this.blockContactAddress2City ||
				this.blockContactAddress2State ||
				this.blockContactAddress2Zip
			);
		},
		hasAddress3Section() {
			if (!this.settings?.["address3-show"]) return false;

			return !!(
				this.blockContactAddress3Line1 ||
				this.blockContactAddress3City ||
				this.blockContactAddress3State ||
				this.blockContactAddress3Zip
			);
		},
		blockContactAddressLabel() {
			const label = this.settings.address?.label;
			if (label && label.length <= 100) {
				return renderData(
					this.settings.address?.label + ": &nbsp;",
					this.dataSite
				);
			}
			return false;
		},
		blockContactAddressLine1() {
			if (this.isMemberSelected) return this.memberSelected.address;
			return renderData(this.settings.address?.line1, this.dataSite);
		},
		blockContactAddressLine2() {
			if (this.isMemberSelected) return "";
			return renderData(this.settings.address?.line2, this.dataSite);
		},
		blockContactAddressCity() {
			if (this.isMemberSelected) return this.memberSelected.city;
			return renderData(this.settings.address?.city, this.dataSite);
		},
		blockContactAddressState() {
			if (this.isMemberSelected) return this.memberSelected.state;
			return renderData(this.settings.address?.state, this.dataSite);
		},
		blockContactAddressZip() {
			if (this.isMemberSelected) return this.memberSelected.postalCode;
			return renderData(this.settings.address?.zip, this.dataSite);
		},
		blockContactAddress2Label() {
			const label = this.settings.address2?.label;
			if (label && label.length <= 100) {
				return renderData(
					this.settings.address2?.label + ": &nbsp;",
					this.dataSite
				);
			}
			return false;
		},
		blockContactAddress2Line1() {
			return renderData(this.settings.address2?.line1, this.dataSite);
		},
		blockContactAddress2Line2() {
			return renderData(this.settings.address2?.line2, this.dataSite);
		},
		blockContactAddress2City() {
			return renderData(this.settings.address2?.city, this.dataSite);
		},
		blockContactAddress2State() {
			return renderData(this.settings.address2?.state, this.dataSite);
		},
		blockContactAddress2Zip() {
			return renderData(this.settings.address2?.zip, this.dataSite);
		},
		blockContactAddress3Label() {
			const label = this.settings.address3?.label;
			if (label && label.length <= 100) {
				return renderData(
					this.settings.address3?.label + ": &nbsp;",
					this.dataSite
				);
			}
			return false;
		},
		blockContactAddress3Line1() {
			return renderData(this.settings.address3?.line1, this.dataSite);
		},
		blockContactAddress3Line2() {
			return renderData(this.settings.address3?.line2, this.dataSite);
		},
		blockContactAddress3City() {
			return renderData(this.settings.address3?.city, this.dataSite);
		},
		blockContactAddress3State() {
			return renderData(this.settings.address3?.state, this.dataSite);
		},
		blockContactAddress3Zip() {
			return renderData(this.settings.address3?.zip, this.dataSite);
		},
		buttonSettings() {
			return {
				type: "button",
				icon: this.settings.buttonIcon,
				label: this.settings.buttonLabel,
				newWindow: this.settings.buttonNewTab, // does this do anything?
				url: this.settings.buttonUrl,
				variants: {
					"icon-placement":
						this.settings.buttonIconPlacement || "icon-right",
					style: this.settings?.variants["buttonStyle"] || "primary",
				},
			};
		},
		button2Settings() {
			return {
				type: "button",
				icon: this.settings.buttonIcon2,
				label: this.settings.buttonLabel2,
				url: this.settings.buttonUrl2,
				variants: {
					"icon-placement":
						this.settings.buttonIconPlacement2 || "icon-right",
					style: "secondary",
				},
			};
		},
		socialLinksSettings() {
			return {
				type: "social-links",
				facebook: this.blockFacebook,
				instagram: this.blockInstagram,
				linkedin: this.blockLinkedin,
				twitter: this.blockTwitter,
				youtube: this.blockYoutube,
			};
		},
		cleanAddress() {
			return (
				`http://maps.google.com/?q=` +
				encodeURIComponent(
					`${this.blockContactAddressLine1} ${this.blockContactAddressLine2} ${this.blockContactAddressCity} ${this.blockContactAddressState} ${this.blockContactAddressZip}`
				)
			);
		},
		cleanAddress2() {
			return (
				`http://maps.google.com/?q=` +
				encodeURIComponent(
					`${this.blockContactAddress2Line1} ${this.blockContactAddress2Line2} ${this.blockContactAddress2City} ${this.blockContactAddress2State} ${this.blockContactAddress2Zip}`
				)
			);
		},
		cleanAddress3() {
			return (
				`http://maps.google.com/?q=` +
				encodeURIComponent(
					`${this.blockContactAddress3Line1} ${this.blockContactAddress3Line2} ${this.blockContactAddress3City} ${this.blockContactAddress3State} ${this.blockContactAddress3Zip}`
				)
			);
		},
		blockFacebook() {
			if (!this.settings?.["facebook-show"]) return "";
			if (this.isMemberSelected) return this.memberSelected.facebookUrl;
			return renderData(this.settings.facebook, this.dataSite);
		},
		blockLinkedin() {
			if (!this.settings?.["linkedin-show"]) return "";
			if (this.isMemberSelected) return this.memberSelected.linkedInUrl;
			return renderData(this.settings.linkedin, this.dataSite);
		},
		blockInstagram() {
			if (!this.settings?.["instagram-show"]) return "";
			if (this.isMemberSelected) return "";
			return renderData(this.settings.instagram, this.dataSite);
		},
		blockTwitter() {
			if (!this.settings?.["twitter-show"]) return "";
			if (this.isMemberSelected) return this.memberSelected.twitterUrl;
			return renderData(this.settings.twitter, this.dataSite);
		},
		blockYoutube() {
			if (this.isMemberSelected) return "";
			return this.settings.youtube || null;
		},
		photoPlacement() {
			return this.settings?.variants["photo-placement"] || "top";
		},
		alignContent() {
			return this.settings?.variants["align-content"] || "align-center";
		},
		photoBackground() {
			return ["background"].includes(this.photoPlacement);
		},
		photoTop() {
			return ["top"].includes(this.photoPlacement);
		},
		photoSides() {
			return !(this.photoTop || this.photoBackground || this.noPhoto);
		},
		licenses() {
			if (this.memberSelected) return this.memberSelected.licenses || [];
			return this.dataSite?.user?.ddcUserData?.ddcLicenseData || [];
		},
		licensesCalifornia() {
			return this.getLicenses("CA");
		},
		licensesArkansas() {
			return this.getLicenses("AR");
		},
		isDBA() {
			return (
				this.dataSite?.user?.ddcUserData?.dbaWebsiteOptIn ??
				!!this.dataSite?.user?.ddcUserData?.dbaName
			);
		},
		hasSocialIcons() {
			return (
				this.blockFacebook ||
				this.blockInstagram ||
				this.blockLinkedin ||
				this.blockTwitter ||
				this.blockYoutube
			);
		},
		hasDuplicateTitle() {
			if (this.managingPartner && this.blockContactTitle) {
				if (
					this.managingPartner.toLowerCase().trim() ===
					this.blockContactTitle.toLowerCase().trim()
				) {
					return true;
				}
			}
			return false;
		},
		memberSelected() {
			return this.dataSite?.teamMembers?.find(
				(t) =>
					this.settings["team-member"] !== -1 &&
					t.id === this.settings["team-member"]
			);
		},
		isMemberSelected() {
			return !!this.memberSelected;
		},
	},
	methods: {
		verifyAgentState(stateValue) {
			return this.dataSite?.user?.ddcUserData?.discBusLocAddrStateCode ===
				stateValue
				? true
				: false;
		},
		clickToCall(phoneNumber, extension) {
			const cleanPhoneNumber =
				phoneNumber && phoneNumber.replace(/[^0-9+]/g, "");
			const cleanExtension =
				extension && extension.replace(/[^0-9+]/g, "");
			if (cleanExtension)
				return `tel:${cleanPhoneNumber};${cleanExtension}`;
			return `tel:${cleanPhoneNumber}`;
		},
		getLicenses(stateCountyCode) {
			if (
				this.$store?.state?.settings?.featureFlags?.NewDisclosureLogic
			) {
				let licenses = licenseItems(
					{
						stateCountyCode: stateCountyCode,
						licenseLobCode: "80",
						busLicenseTpCode: "I",
						busEntityCode: "001",
					},
					this.licenses
				);

				licenses = licenses.concat(
					licenseItems(
						{
							stateCountyCode: stateCountyCode,
							licenseLobCode: ["80", "70", "60", "50", "40"],
							busLicenseTpCode: "I",
							busEntityCode: "002",
						},
						this.licenses
					)
				);

				licenses = licenses.concat(
					licenseItems(
						{
							stateCountyCode: stateCountyCode,
							licenseLobCode: "80",
							busLicenseTpCode: "I",
							busEntityCode: "004",
						},
						this.licenses
					)
				);

				return licenses.filter(
					(obj, index, self) =>
						index ===
						self.findIndex(
							(t) => t.stateCountyCode === obj.stateCountyCode
						)
				);
			} else {
				return licenseItems(
					{
						stateCountyCode: stateCountyCode,
						licenseLobCode: "80",
						busLicenseTpCode: "I",
						busEntityCode: "001",
					},
					this.licenses
				);
			}
		},
	},
};
